import './main.css';
import '../.tailwind-gen.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


const node = document.getElementById('root');
Elm.Main.init({ flags: 0, node: node });
